import {
  createRouter,
  createWebHashHistory
} from "vue-router";
import IntentionOfChainStores from '@/components/intentionOfChainStores/intentionOfChainStores'
const router = createRouter({
  history:createWebHashHistory(),
  routes: [
    {
      // 公司简介
      path: '/',
      name: 'companyProfile',
      component:() => import("../components/companyProfile/companyProfile.vue")
    },
    {
      // 门店查询
      path: '/oneStopTransparency',
      name: 'oneStopTransparency',
      component:() => import("../components/oneStopTransparency/oneStopTransparency.vue")
    },
    {
      // 项目介绍
      path: '/projectDetails',
      name: 'projectDetails',
      component:() => import("../components/util/projectDetails/projectDetails.vue")
    },
    {
      // 专利详解
      path: '/patentExplanation',
      name: 'patentExplanation',
      component:() => import("../components/util/patentExplanation/patentExplanation.vue")
    },
    {
      //
      path: '/intentionOfChainStores',
      name: 'intentionOfChainStores',
      component: IntentionOfChainStores
    },
    {//产品优势
      path: '/contrast',
      name: 'contrast',
      component:() => import("../components/contrast/contrast.vue")
    },
    {//市场分析
      path: '/marketAnalysis',
      name: 'marketAnalysis',
      component:() => import("../components/marketAnalysis/marketAnalysis.vue")
    },
    {//安全保障
      path: '/conditionsMet',
      name: 'conditionsMet',
      component:() => import("../components/conditionsMet/conditionsMet.vue")
    },
    {//市场规划
      path: '/marketPlanning',
      name: 'marketPlanning',
      component:() => import("../components/marketPlanning/marketPlanning.vue")
    },
    {//为什么选择我们
      path: '/reasonForSelection',
      name: 'reasonForSelection',
      component:() => import("../components/reasonForSelection/reasonForSelection.vue")
    },
    {//商学院
      path: '/help',
      name: 'help',
      component:() => import("../components/help/help.vue")
    },
    {//登录
      path: '/login',
      name: 'login',
      component:() => import("../components/login/login.vue")
    },
    {//登录
      path: '/outbound',
      name: 'outbound',
      component:() => import("../components/outbound/outbound.vue")
    },
    {//门店首页
      path: '/storeHome',
      name: 'storeHome',
      component:() => import("../components/store/storeHome/storeHome.vue"),
      meta:{
        keepAlive:true // 需要缓存
      }
    },
    {//门店商品详情
      path: '/storeMenuDetails',
      name: 'storeMenuDetails',
      component:() => import("../components/store/storeMenuDetails/storeMenuDetails.vue")
    },
    {//地址列表
      path: '/address',
      name: 'address',
      component:() => import("../components/address/address.vue")
    },

  ]
})
export default router;
