import axios from "axios";
import {
  ElMessage
} from "element-plus";
import router from "../router";
import sha1 from "js-sha1";
export const h5Http = "https://www.ccshengtai.top";
// export const h5Http = "http://localhost:8082";
export const httpIp = "https://ww3.ccshengtai.top";
// export const httpIp = "http://localhost:8035";

const gwService = axios.create({
    baseURL:httpIp,
  timeout: 60000,
});



// Request interceptors
gwService.interceptors.request.use(
  (config) => {
      if (localStorage.getItem('token')&&config.isNotToken===true){
          let nonce = new Date().getTime()
          let sign2 = sha1(localStorage.getItem('token')+nonce);
          config.headers.sign2 = sign2
          config.headers.uuid = JSON.parse(localStorage.getItem('user')).uuid
          config.headers.nonce = nonce;
          // config.url=
      }


    return config;
  },
  (error) => {
      Promise.reject(error);
  }
);

// Response interceptors
gwService.interceptors.response.use(
  (response) => {
    if (response.status !== 200) {

      ElMessage({
        type: "error",
        message: "服务器忙,请稍后再试~",
      });
      return;
    }

    return response;
  },
  (error) => {
      console.log(error)
      if (error.response.status===401){
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("wxGzQrCodeUrl")
          ElMessage({
              type: "warning",
              message: "登录过期，请您重新登录！",
          });
          router.push("/login");
      }else if (error.response.status===403){
          ElMessage({
              type: "warning",
              message: "无权限！请您反馈",
          });
      }else if (error.response.status===408){
          ElMessage({
              type: "warning",
              message: "对不起，请求超时，请您重试",
          });
      } else if (error.response.status===500){
          ElMessage.error("错误！请您反馈")
      }else if (error.response.status===404){
          ElMessage.error("当前功能不可用，请反馈")
      }else {
          ElMessage.error("服务暂不可用，请稍后重试")

      }
    // do something
    return Promise.reject(error);
  }
);

export default gwService;
