<template>
  <Transition name="el-zoom-in-top">
    <div @click="isNoShow" @touchmove.prevent @mousewheel.prevent  v-show="show" style="background:rgba(40,44,53,0.9);height: 100%;position: fixed;z-index: 18;width: 100%;top: 0">
      <div  style="width: 100%;margin-top: 5vh;margin-bottom: 3vh">
        <el-image :src="require('@/assets/image/logo.png')" fit="contain" style="width: 10vh;margin: 0 auto" mode = "aspectFit"></el-image>
      </div>
      <div style="margin-top: 0;font-size: 24px;font-weight: bold;color: white">
        问题反馈/举报
      </div>
      <div ref="fanKui" @touchmove.stop="listTouch" @mousewheel.stop="listTouch" :style="[{width:pageState===1?'60vw':'90vw'},{height:pageState===1?'65vh':'65vh'},{padding:pageState===1?'7vh 8vw':'6vh 5vw'}]"  @click.stop style=" scrollbar-width: none; /* Firefox */-ms-overflow-style: none; /* IE and Edge */;position: relative;overflow: scroll;border-radius: 8px;margin: 26px auto 0 auto;background-color: white" class="border">

        <el-form
            :model="officialWebsiteOpinions"
            :rules="rules"
            ref="FormRef"
            class="demo-ruleForm"
        >
          <el-form-item label="反馈类型" :label-width="formLabelWidth" prop="typeList">
            <el-select clearable v-model="officialWebsiteOpinions.typeId" placeholder="请选择反馈类型">
              <el-option v-for="item in typeList" :label="item.typeName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="辅助图片" :label-width="formLabelWidth" prop="img">
            <el-row>
<!--                <el-upload-->
<!--                    v-model:file-list="fileList"-->
<!--                    :action="httpIp+'/oss/gw/add/file'"-->
<!--                    list-type="picture-card"-->
<!--                    :on-preview="handlePictureCardPreview"-->
<!--                    :on-remove="handleRemove"-->
<!--                    :headers="headersObj"-->
<!--                    :before-upload="getSign"-->
<!--                    :limit="5"-->
<!--                    :on-exceed="handleExceed"-->
<!--                    :auto-upload="true"-->
<!--                    :on-success="handleSuccess"-->
<!--                >-->
<!--                  <el-icon><Plus /></el-icon>-->
<!--                </el-upload>-->
              <el-upload
                  v-model:file-list="fileList"
                  :action="httpIp+'/oss/gw/add/file'"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :headers="headersObj"
                  :limit="5"
                  :on-exceed="handleExceed"
                  :auto-upload="true"
                  :on-success="handleSuccess"
              >
                <el-icon><Plus /></el-icon>
              </el-upload>
            </el-row>
            <el-image-viewer v-if="imageDialogVisible" @close="imageView" style="width: 100px; height: 100px" :url-list="[dialogImageUrl]" />
          </el-form-item>
          <el-form-item style="margin-bottom: 10vh" label="问题描述" :label-width="formLabelWidth" prop="contentStr">
            <el-input clearable v-model="officialWebsiteOpinions.contentStr" type="textarea" autocomplete="off" />
          </el-form-item>
        </el-form>
<!--        提交按钮-->

<!--        <template>-->
<!--          <span>-->
<!--            <el-button style="width: clamp(70px,8vw,150px);height: clamp(32px,2vw,50px)"  @click="isNoShow">取消</el-button>-->
<!--          </span>-->
<!--        </template>-->
        <el-button type="primary" style="width: clamp(70px,8vw,150px);height: clamp(32px,2vw,50px)"  @click="tiJiao()">确认</el-button>

      </div>
    </div>
  </Transition>
</template>
<script>
import {getCurrentInstance, watch} from 'vue';
import {ElMessage,FormInstance} from "element-plus";
import sha1 from "js-sha1";
import {gwDeleteFileByUrl,deleteFile, addFile, deleteFileByUrl} from '@/api/modules/upload'
import {httpIp} from "@/api/request";
import {userGetList,addEFeedbackRecord} from "@/api/modules/feedback"
export default {
  computed: {
    data(){
      return  this.$pageState.state
    },
  },
  components: {

  },
  props:{
    info:String
  },
  data(){
    return{
      formLabelWidth:"100px",
      httpIp:httpIp,
      pageState:0,
      picturePreviewKaiGuan:false,
      nowUrl:"",
      show:false,
      officialWebsiteOpinions:{
        //意见类型id
        typeId:null,
        //反馈描述
        contentStr:"",
        //反馈图片数组字符串
        contentUrl:"",
        //意见来源 1.商城小程序 2.官网
        platformType:2
      },
      //反馈图片数组
      imageList:[],
      rules:{
        typeList: [{ required: true, message: '请选择意见类型', trigger: 'blur' ,}],
        contentStr: [{ required: true, message: '请填写意见内容', trigger: 'blur' }],

      },
      //图片预览弹窗状态
      imageDialogVisible:"",
      //预览图片url
      dialogImageUrl:"",
      headersObj:{},
      fileList:[],
      uploadRecords:[],
      //反馈类型数组
      typeList:[]
    }
  },
  mounted() {
    this.getPageState();
    // this.getFeedbackTypeList();
  },
  watch:{
    data(newValue){
      this.pageState = newValue
    }
  },
  methods:{
    //提交意见
    async tiJiao(){

      if (!this.$refs.FormRef) return
      this.$refs.FormRef.validate()
      this.officialWebsiteOpinions.contentUrl = JSON.stringify(this.imageList)
      console.log(this.officialWebsiteOpinions.contentUrl)
      let data = JSON.parse(JSON.stringify(this.officialWebsiteOpinions));
      if (this.info.id!==null){
        data.contentStr=this.info.id+":"+this.info.storeName+data.contentStr
      }
      await addEFeedbackRecord(data).then(res=>{
        if(res.data.code===1001){
          this.$refs.FormRef.resetFields()
          ElMessage.success(res.data.msg)

          this.imageSubmitTo=true;
          setTimeout(() => {

            this.isNoShow();
          }, 1000)
        }else{
          ElMessage.error(res.data.msg)
        }
      })
    },
    //todo 滑动穿透
    listTouch(e) {
      //scrollHeight为滑动区内部内容的高度，
      //clientHeight为滑动区的高度
      //scrollHeight大于clientHeight允许滑动
      console.log(this.$refs.fanKui.scrollHeight)
      console.log(this.$refs.fanKui.clientHeight)
      if (!(this.$refs.fanKui.scrollHeight > this.$refs.fanKui.clientHeight)) {
        e.preventDefault();
      }
    },
    //todo 获取反馈类型列表
    getFeedbackTypeList(){
      userGetList().then(res=>{
        if (res.data.code===1001){
          this.typeList=res.data.body;

        }else {
          ElMessage.error(res.data.msg)
        }

      })
    },
    //todo 关闭图片预览
    imageView(){
      this.imageDialogVisible = false;
      this.picturePreviewKaiGuan = false;
    },
    //todo 上传文件，更新密钥,时间戳
    getSign(){
      let nonce = new Date().getTime();
      this.headersObj = {
        "sign2": sha1(localStorage.getItem('token')+nonce),
        "uuid":JSON.parse(localStorage.getItem('user')).uuid,
        "nonce":nonce
      }
      console.log("1333131")
    },
    //todo 删除上传的图片
    handleRemove(file){
      console.log(this.fileList)
      console.log(file)
      if (this.uploadRecords[file.name]===null||this.uploadRecords[file.name]===undefined||this.uploadRecords[file.name]===""){
        return;
      }
      const parameter = {"key":this.uploadRecords[file.name]}
      gwDeleteFileByUrl(parameter).then(()=>{
        ElMessage.success("已删除")
      }).catch(()=>{
        ElMessage.error("删除失败，请联系管理员")

      })
    },
    //todo 上传完成后
    handleSuccess(res) {
      if (res.code===1001){
        ElMessage.success(res.msg)
        // dialogData.FormData.headUrl=
        this.uploadRecords[res.body.fileName]=res.body.key
        this.imageList.push(res.body.url)
        // dialogData.FormData.pictureImage=res.body.url
      }else {
        ElMessage.error(res.msg)
      }

    },
    //todo 上传文件数量超过limit
    handleExceed(){
      ElMessage.error("上传数量上限，请删除已有")
    },
    handlePictureCardPreview(file){
      this.dialogImageUrl = file.url;
      this.imageDialogVisible = true;
    },
    isNoShow(state){

      if (state===true||state===false){
        this.show = state

      }else {
        this.show=!this.show

      }
      if (this.show===true){
        //获取分类类型
        this.getFeedbackTypeList()
      }else {
        this.$fanKuiState.state = 0

      }
    },
    getPageState(){
      this.pageState = this.$pageState.state
    },
    yuLan(url){
      this.nowUrl = url;
      this.picturePreviewKaiGuan= true
    }
  }
}

</script>
<style>
.menu-enter-from,.menu-leave-to{
  opacity: 0;
  transform: rotate(0turn);
}
.menu-enter-active,.menu-leave-active{
  transition: all 0.3s ease;
}
</style>