import service from "../request"

export function userGetList (config) {  //获取反馈类型列表
	return service({
		url:'feedbackType/user/list/get',
		method:'GET',
		data:config,
		isNotToken:false
	})
}

export function addEFeedbackRecord (config) {  //获取反馈类型列表
	return service({
		url:'eFeedbackRecord/user/add',
		method:'POST',
		data:config,
		isNotToken:false
	})
}